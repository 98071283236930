import { useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import styles from './AppContainer.module.scss';
import Login from '../../auth/login/Login';
import Register from '../../auth/register/Register';
import ProductsPage from '../../components/products-page/ProductsPage';
import CmsContainer from '../cms-container/CmsContainer';
import Header from '../../shared/header/Header';
import Cart from '../../components/cart/Cart';
import ProductDetailsView from '../../components/product-details-view/ProductDetailsView';
import ProducerDetailsView from '../../components/producer-details-view/ProducerDetailsView';
import Order from '../../components/order/Order';
import OrderConfirmation from '../../components/order-confirmation/OrderConfirmation';
import ProducersPage from '../../components/producers-page/ProducersPage';
import ClientPanel from '../../components/client-panel/ClientPanel';
import OrdersHistory from '../../components/client-panel/components/orders-history/OrdersHistory';
import AddressDetails from '../../components/client-panel/components/address-details/AddressDetails';
import MyAccountInfo from '../../components/client-panel/components/my-account-info/MyAccounInfo';
import UserDetails from '../../components/client-panel/components/user-details/UserDetails';
import OrderDetails from '../../components/client-panel/components/order-details/OrderDetails';
import SellingSessions from '../../components/selling-sessions/SellingSessions';
import { usePluginStore } from 'react-pluggable';
import PickupMethods from '../../components/pickup-methods/PickupMethods';
import {
    ABOUT, COLLABORATION, CONTACT, DELIVERY, HOW_TO_BUY, JOIN_US, LOGO, PARTNERS, PAYMENTS, WEBSITE_REGULATIONS,
    PRIVACY_POLICY, REGULATIONS,
    PRZELEWY24_REGULATIONS,
    PRZELEWY24_OBLIGATIONS
} from '../../shared/constants/cms-codes.constants';
import { CmsTitlesAndUrls } from '../cms-container/models/cms-titles-url-by-code';
import { getCmsUrlAndTitleByLanguagAndCode } from '../cms-container/services';
import OrderTransactionConfirmation from '../../components/order-transaction-confirmation/OrderTransactionConfirmation';
import Przelewy24PaymentPage from '../../components/przelewy24-payment-page/Przelewy24PaymentPage';
import ContinueOrCreateAccount from '../continue-or-create-account/ContinueOrCreateAccount';
import ResetPaswordContainer from '../reset-password-container/ResetPaswordContainer';

export default function AppContainer() {
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");
    const [cartItemsChanged, setCartItemsChanged] = useState<boolean>(false);
    const [titlesAndUrls, setTitlesAndUrls] = useState<CmsTitlesAndUrls>({});
    const [loading, setLoading] = useState(true);
    const cmsContainerRef = useRef<HTMLElement | null>(null);
    const location = useLocation();
    const [cmsContainerLoaded, setCmsContainerLoaded] = useState(false);

    const getTitleAndUrlForHeader = async (): Promise<void[]> => {
        const codes = [HOW_TO_BUY, CONTACT, JOIN_US, ABOUT, LOGO, WEBSITE_REGULATIONS,
            DELIVERY, PAYMENTS, COLLABORATION, PARTNERS, PRIVACY_POLICY, REGULATIONS];
        const savedLanguageCode = localStorage.getItem('lang');
        if (savedLanguageCode) {
            const promises = codes.map(async (code) => {
                const response = await getCmsUrlAndTitleByLanguagAndCode(code, savedLanguageCode);
                setTitlesAndUrls((prevTitlesAndUrls) => ({
                    ...prevTitlesAndUrls,
                    [code]: response.data,
                }));
            });
            await Promise.all(promises);
            setLoading(false);
        }

        return Promise.resolve([]);
    };


    function handleCartItemsChange(): void {
        setCartItemsChanged(!cartItemsChanged);
    }

    function handleRemoveCartItem(): void {
        setCartItemsChanged(!cartItemsChanged);
    }


    useEffect(() => {
        getTitleAndUrlForHeader();
    }, [localStorage.getItem('lang')]);

    useEffect(() => {
    }, [loading, titlesAndUrls]);

    const scrollToCmsContainer = () => {
        if (cmsContainerRef.current) {
            window.scrollTo({
                top: cmsContainerRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    const handleCmsContainerRef = (element: HTMLElement | null) => {
        cmsContainerRef.current = element;
        if (element) setCmsContainerLoaded(true);
    };

    useEffect(() => {
        scrollToCmsContainer();
    }, [location.pathname, cmsContainerLoaded]);

    return (
        <div className={styles.main_container}>
            <div className={styles.header}>
                <Header cartItemsChanged={cartItemsChanged} />
            </div>
            <div className={styles.content}>
                <Routes>
                    <Route path='/:language?' element={<ProductsPage onCartItemsChange={handleCartItemsChange} />} />
                    <Route path='/:language?/catalogue/:categoryId' element={<ProductsPage onCartItemsChange={handleCartItemsChange} />} />
                    <Route path='/:language?/login' Component={Login} />
                    <Route path='/:language?/register' Component={Register} />
                    <Route path='/:language?/client-panel' Component={ClientPanel} >
                        <Route path='' element={<MyAccountInfo />} />
                        <Route path='orders' element={<OrdersHistory />} />
                        <Route path='orders/:orderId' element={<OrderDetails />} />
                        <Route path='address' element={<AddressDetails />} />
                        <Route path='user-details' element={<UserDetails />} />
                    </Route>
                    <Route path='/:language?/cart' element={<Cart onCartItemRemoved={handleRemoveCartItem} onCartItemsChange={handleCartItemsChange} />} />
                    <Route path='/:language?/product-details/:productId/:categoryId/:producerId?' element={<ProductDetailsView onCartItemsChange={handleCartItemsChange} />} />
                    <Route path='/:language?/producer-details-view/:producerId' element={<ProducerDetailsView onCartItemsChange={handleCartItemsChange} />} />
                    <Route path='/:language?/order' element={<Order onCartItemRemoved={handleRemoveCartItem} onCartItemsChange={handleCartItemsChange} />} />
                    <Route path='/:language?/order-confirmation/:orderId' element={<OrderConfirmation />} />
                    <Route path='/:language?/register-transaction-confirmation' element={<OrderTransactionConfirmation />} />
                    <Route path='/:language?/producers' element={<ProducersPage />} />
                    <Route path='/:language?/selling-sessions' element={<SellingSessions />} />
                    <Route path='/:language?/pickup-methods' element={<PickupMethods />} />
                    <Route path='/:language?/continue-or-create-account' element={<ContinueOrCreateAccount />} />
                    <Route path='/:language?/przelewy24payment/:orderId' element={<Przelewy24PaymentPage />} />
                    {<Route path={`/:language?${titlesAndUrls.HOW_TO_BUY?.url}`} element={<CmsContainer code={HOW_TO_BUY} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.CONTACT?.url}`} element={<CmsContainer code={CONTACT} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.ABOUT?.url}`} element={<CmsContainer code={ABOUT} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.JOIN_US?.url}`} element={<CmsContainer code={JOIN_US} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.LOGO?.url}`} element={<CmsContainer code={LOGO} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.WEBSITE_REGULATIONS?.url}`} element={<CmsContainer code={WEBSITE_REGULATIONS} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.DELIVERY?.url}`} element={<CmsContainer code={DELIVERY} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.PAYMENTS?.url}`} element={<CmsContainer code={PAYMENTS} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.COLLABORATION?.url}`} element={<CmsContainer code={COLLABORATION} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.PARTNERS?.url}`} element={<CmsContainer code={PARTNERS} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.REGULATIONS?.url}`} element={<CmsContainer code={REGULATIONS} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.PRZELEWY24_REGULATIONS?.url}`} element={<CmsContainer code={PRZELEWY24_REGULATIONS} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.PRZELEWY24_OBLIGATIONS?.url}`} element={<CmsContainer code={PRZELEWY24_OBLIGATIONS} />}></Route>}
                    {<Route path={`/:language?${titlesAndUrls.PRIVACY_POLICY?.url}`} element={<CmsContainer code={PRIVACY_POLICY} />}></Route>}
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/:language?/reset-password/*" Component={ResetPaswordContainer}></Route>
                </Routes >

            </div >
            <div className={styles.footer}>
                <Renderer placement="page:footer" />
            </div>
        </div >
    );
}
